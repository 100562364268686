import './App.css';
import Header from "./components/Header/Header";
// import Ticker from "./components/Ticker/Ticker";
import { Switch, Route } from "react-router-dom"
import Home from "./components/Home/Home";
import About from "./components/About/About";
// import CryptoBasics from "./components/CryptoBasics/CryptoBasics";
import Shop from "./components/Shop/Shop";
import NFT from "./components/NFT/NFT";
import AffiliateLinks from "./components/AffiliateLinks/AffiliateLinks";
import CryptoNews from "./components/CryptoNews/CryptoNews";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
        <Header/>
        {/*<Ticker/>*/}
        <Switch>
            <Route exact path={"/"}>
                <Home/>
            </Route>
            <Route exact path={"/about"}>
                <About/>
            </Route>
            <Route exact path={"/book"}>
                <Shop/>
            </Route>
            {/*<Route exact path={"/shop"}>*/}
            {/*    <Shop/>*/}
            {/*</Route>*/}
            <Route exact path={"/nft"}>
                <NFT/>
            </Route>
            <Route exact path={"/links"}>
                <AffiliateLinks/>
            </Route>
            <Route exact path={"/news"}>
                <CryptoNews/>
            </Route>
        </Switch>
        <Footer/>
    </div>
  );
}

export default App;
