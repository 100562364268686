function NFTAssets(props) {

    return(props.assets.map((asset) => {
        return(
            <div className="asset" key={asset.permalink}>
                <a href={asset.permalink} target="_blank" rel="noopener noreferrer">
                    <img src={asset.image_url} alt=""/>
                    <div className="asset-info">
                        <div className="left">
                            <p className={"collection-name"}>{asset.collection.name}</p>
                            <p className={"name"}>{asset.name}</p>
                        </div>
                        <div className="right">
                            <p className={"price-label"}>Price</p>
                            <p className={"price"}>0.02</p>
                        </div>
                    </div>
                </a>
            </div>
        );
    }))
}

export default NFTAssets;