function TickerCoins(props) {
    return(
        <div className={"ticker"}>
            {props.coins.map((coin) => {
                return(
                    <div className={"ticker-coin"} key={coin.id}>
                        <img src={coin.image} alt={coin.name} onClick={()=> window.open(`https://www.binance.com/en/trade/${coin.symbol.toUpperCase()}_USDT`, "_blank")}/>
                        <div className={"coin-name"}>{coin.name}</div>
                        <div className={"coin-price"}>${coin.current_price}</div>
                    </div>
                );
            })}
        </div>
    );
}

export default TickerCoins;