import './Pagination.css';

const Pagination = (props) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(props.totalPosts / props.postsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav>
            <ul className='pagination'>
                {pageNumbers.map(number => (
                    <li key={number} onClick={() => props.paginate(number)} className={number === props.currentPage ? "active" : ""}>
                        {number}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Pagination;