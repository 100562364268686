import seperator from '../../images/sep.png';

function CryptoNewsArticles(props) {
    return(props.articles.map((article) => {
        // let articleDate = new Date(article.pubDate);
        // article.pubDate = articleDate.toLocaleString();
            article.description = article.description.replace(/<\/?[^>]+(>|$)/g, "");
        return(
            <div className={"article"} key={article.link}>
                <a href={article.link} target="_blank" rel="noopener noreferrer"><img className={"thumbnail"} src={article.thumbnail} alt={article.title} /></a>
                <img className={"sep"} src={seperator} alt="sep"/>
                <div className={"article-body"}>
                        <a href={article.link} target="_blank" rel="noopener noreferrer"><h5>{article.title}</h5></a>
                        <p className={"description"}>{article.description}</p>
                        <p>{article.pubDate}</p>
                </div>
            </div>
        )
        })
    );
}

export default CryptoNewsArticles;