import './NFT.css';
import {useState, useEffect} from 'react';
import NFTAssets from './NFTAssets';
// import Pagination from '../Pagination/Pagination';
import searchIcon from '../../images/search.png';

function NFT() {

    const [assets, setAssets] = useState([]);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [assetsPerPage] = useState(8);
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);

    useEffect(()=> {
        fetch('https://api.opensea.io/api/v1/assets?owner=0x207d9c05fad3c11a1cf1a905d4929c9310dd42fd')
            .then(response => response.json())
            .then(data => {
                setAssets(data.assets);
                setFilteredResults(data.assets);
            });
        console.log('got assets');
    },[]);

    // const indexOfLast = currentPage * assetsPerPage;
    // const indexOfFirst = indexOfLast - assetsPerPage;
    // const currentAssets = assets.slice(indexOfFirst, indexOfLast);
    //
    // const paginate = pageNumber => setCurrentPage(pageNumber);

    const handleSearch = (value) => {
        setSearchInput(value);

        const filteredData = assets.filter((item) => {
            return Object.values(item.name).join('').toLowerCase().includes(searchInput.toLowerCase());
        });

        setFilteredResults(filteredData);
    }

    return(
        <div>
            <div className={"nft-landing"}>
                <h1>NFT'S</h1>
            </div>

            <div className="collection-heading">
                {/*<h1>Egglings Collection</h1>*/}
                {/*<p>*/}
                {/*    Egglings are the first series of collectable Eggs that will transform into Hatchlings & Chicklings.*/}
                {/*    There will be 111 Eggs Available, 111 Hatchlings and 111 Chicklings! Let the Egg hunt commence!*/}
                {/*</p>*/}
                <h1>What is an NFT?</h1>
                <p>
                    NFT stands for non-fungible token. I beg your pardon? I hear you cry, what is that though?
                    Non-fungible means that it is unique and it cannot be replaced. When something is fungible,
                    it can be replaced by another identical item. For example, if you had one pound coin and
                    swapped it with your friend’s one pound coin, you would still have the exact same thing.
                    Whereas with something that is non-fungible, for example a one-of-a-kind pair of trainers, if
                    you swapped them with your friend’s leather jacket, you would have something that is
                    completely different.
                    So now we know what non-fungible means, we need to look at tokens as this is what ties
                    this to the world of Cryptocurrency. A token is a tradable asset or utility that is normally
                    built on an existing blockchain. When we talk about NFT’s, usually they are built upon and
                    supported by the Ethereum blockchain, using smart contracts. As NFT’s have grown in
                    popularity however, more and more blockchains are creating their own support for NFT’s.
                    The NFT space is growing every single day and is a huge platform for artists and designers
                    from all backgrounds whether that is through illustration, music, design, architecture, sport
                    fashion etc.. You name it, the NFT space will soon include and accommodate it!
                    Using my artistic background, I began creating NFT’s in 2021 and currently have 2
                    collections available on the platform OpenSea. Browse my collections below!</p>
            </div>

            <div className="search">
                <img src={searchIcon} alt="search icon" width="500" height="500"/>
                <input onChange={(e) => handleSearch(e.target.value)} type="text" placeholder={"Search"} className="search-bar"/>
            </div>

            {searchInput.length > 1 ? (
                <div className="assets">
                    <NFTAssets assets={filteredResults} />
                </div>
            ) : (
                <>
                    <div className="assets">
                        <NFTAssets assets={assets} />
                    </div>

                    {/*<Pagination*/}
                    {/*    postsPerPage={assetsPerPage}*/}
                    {/*    totalPosts={assets.length}*/}
                    {/*    paginate={paginate}*/}
                    {/*    currentPage={currentPage}*/}
                    {/*/>*/}
                </>
            )}

        </div>
    );
}

export default NFT;