import './Ticker.css';
import { useState, useEffect } from 'react';
import TickerCoins from "./TickerCoins";

function Ticker() {

    const [coins, setCoins] = useState([]);

    useEffect(()=> {
        fetch('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=15&page=1&sparkline=false')
            .then( response => response.json() )
            .then( data => {
                const filteredData = data.filter(coin => coin.id === 'bitcoin' || coin.id === 'ethereum' || coin.id === 'cardano' || coin.id === 'binancecoin' || coin.id === 'solana');
                setCoins(filteredData);
            });
        console.log('got data');
    },[]);

    return (
        <div>
            <TickerCoins coins={coins}/>
        </div>
    );
}

export default Ticker;