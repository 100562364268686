import './CryptoNews.css';
import { useState, useEffect } from 'react';
import CryptoNewsArticles from "./CryptoNewsArticles";
import Pagination from "../Pagination/Pagination";


function CryptoNews() {

    const [feedItems, setFeedItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [articlesPerPage] = useState(4);

    useEffect(() => {
        fetch('https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fcointelegraph.com%2Frss')
            .then(response => response.json())
            .then(data => setFeedItems(data.items));
        console.log('got articles');
    }, []);

    const indexOfLast = currentPage * articlesPerPage;
    const indexOfFirst = indexOfLast - articlesPerPage;
    const currentArticles = feedItems.slice(indexOfFirst, indexOfLast);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return(
        <div>
            <div className={"news-landing"}>
                <h1>NEWS</h1>
            </div>
            <div className={"feed"}>
                <CryptoNewsArticles articles={currentArticles}/>
            </div>

            <Pagination
                postsPerPage={articlesPerPage}
                totalPosts={feedItems.length}
                paginate={paginate}
                currentPage={currentPage}
            />
        </div>
    );
}

export default CryptoNews;