import './AffiliateLinks.css';
import aff1 from './../../images/aff1.png';
import aff2 from './../../images/aff2.png';
import aff3 from './../../images/aff3.png';
import aff4 from './../../images/aff4.jpg';
import aff5 from './../../images/aff5.jpg';

function AffiliateLinks() {
    return(
        <div>
            <div className={"affiliate-landing"}>
                <h1>AFFILIATE LINKS</h1>
            </div>

            <div className="affiliate-links">

                <div className="aff-link">
                    <img src={aff1} alt={"BitMex logo"}/>
                    <p>BitMex is a cryptocurrency trading platform that offers investors access to the global
                        financial markets using only Bitcoin. Traders have the option of using 100x leverage on this
                        website.</p>
                    <a href="https://www.bitmex.com/register/pV4DDF" target={"_blank"} rel="noopener noreferrer"><button type={"button"}>See Now</button></a>
                </div>

                <div className="aff-link">
                    <img src={aff2} alt={"Crypto.com logo"}/>
                    <p>Crypto.com is a cryptocurrency exchange that supports trading, staking, wallets, NFTs and
                        more. They are a low cost cryptocurrency exchange that allow investors to buy, sell and
                        earn cryptocurrencies. They are an easy to use platform that even have their own Visa Debit
                        Card.</p>
                    <a href="https://crypto.com/app/s2e2hdky5k" target={"_blank"} rel="noopener noreferrer"><button type={"button"}>See Now</button></a>
                </div>

                <div className="aff-link">
                    <img src={aff3} alt={"Coinbase logo"}/>
                    <p>Coinbase is an easy-to-use exchange for buying, selling, transferring and storing
                        cryptocurrency. They are an excellent platform for beginner’s to easily purchase crypto on.</p>
                    <a href="https://www.coinbase.com/join/stockl_fv" target={"_blank"} rel="noopener noreferrer"><button type={"button"}>See Now</button></a>
                </div>

                <div className="aff-link">
                    <img src={aff4} alt={"Freetrade logo"}/>
                    <p>Freetrade is a simple and affordable way to begin investing in stocks and shares.</p>
                    <a href="https://magic.freetrade.io/join/holly/241bbdeb" target={"_blank"} rel="noopener noreferrer"><button type={"button"}>See Now</button></a>
                </div>

                <div className="aff-link">
                    <img src={aff5} alt={"Market Cipher logo"}/>
                    <p>Market Cipher is a comprehensive package of trading indicators that help traders interpret
                        movements their chosen asset makes. It can be used to interpret any cryptocurrency.</p>
                    <a href="https://marketciphertrading.com?ref=29753" target={"_blank"} rel="noopener noreferrer"><button type={"button"}>See Now</button></a>
                </div>

            </div>
        </div>
    );
}

export default AffiliateLinks;