import './About.css';
import board1 from '../../images/about-board-1-new.png';
import book from '../../images/book-new.png';
import landing3 from "../../images/landing-2.png";
import tiktok from '../../images/tiktok.png';
import amazon from "../../images/amazon.png";

function About() {
    return(
        <div>
            <div className={"about-landing"}>
                <h1>ABOUT ME</h1>
            </div>

            <div className="about-1">
                <h1>Welcome!</h1>
                <div className="about-info">
                    <p>My name is Holly and I am a Cryptocurrency enthusiast and digital nomad wannabe! In 2020, I began my journey into the world of Cryptocurrency which led me to write my book, Crypto Made Clear, and create platforms that make understanding and accessing information about Crypto easy for anyone.</p>
                    <img className={"about-board-1"} src={board1} alt="board"/>
                    <div className="about-book">
                        <img className={"about-board-2"} src={book} alt="board"/>
                        <a className={"amazon-link"} href="https://www.amazon.com/Crypto-Made-Clear-Beginners-Cryptocurrency/dp/183849250X/"><button><span>Buy it on Amazon!</span><img src={amazon} alt="amazon logo"/></button></a>
                    </div>
                    <p>By day, I currently run my own business as an Aesthetic Practitioner in the South West of England. I entered into the world of Cryptocurrency during the 2020 Covid-19 Pandemic when I was forbidden to work by the UK government. At first, learning about Cryptocurrency and how to buy, sell and trade this new digital asset was literally a way for me to support myself and keep me sane during the imposed lockdowns. As the months passed however, it became so much more! My love and passion for this industry is so great now that my goal is to become financially free from Cryptocurrency.</p>
                </div>
            </div>

            <div className="about-2">
                <div className="wrapper">
                    <p>Now, having come from a completely novice background with no prior knowledge or skills in Crypto, I know how daunting and overwhelming this industry can be. Some people are afraid of it, some believe it is a scam and others are just too put off by the volatility the Crypto market can have. This is why I wanted to write Crypto Made Clear, a book that breaks down boundaries and makes this world easily accessible to anyone. From this book, I wanted to create a series of platforms and content to allow people to not only follow my Crypto journey, but to help them create their own!</p>
                </div>
            </div>

            <div className={"landing-section-3"}>
                <div className={"landing-image"}><img src={landing3} alt="landing-3"/></div>
                <div className={"landing3-text"}>
                    <h1>Find us on <span>Instagram</span></h1>
                    <p>You can find us on Instagram, which is our main social media platform, where we post daily useful information, news and guidance! You can also find us on TikTok by searching cryptocountess.</p>
                    <div className={"follow"}>
                        <button type={"button"} onClick={()=> window.open('https://www.instagram.com/cryptocountess', "_blank")}>Follow!</button>
                        <a href="https://www.tiktok.com/@cryptocountess"><img src={tiktok} alt=""/></a>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default About;