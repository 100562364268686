import './Footer.css';
import logo from "../../logo-footer.png";
import {Link, NavLink} from "react-router-dom";
import instagram from '../../images/instagram.png';
import tiktok from '../../images/tiktok.png';

function Footer() {

    return(
        <footer className={"footer"}>

            <div className={"footer-body"}>

                <Link to={"/"} className={"logo-title"}>

                    <img className={"logo"} src={logo} alt=""/>

                </Link>

                <nav className={"nav-links"}>
                    <NavLink to={"/about"} activeClassName={"current-route"}>About</NavLink>
                    <NavLink to={"/book"} activeClassName={"current-route"}>Crypto Made Clear</NavLink>
                    {/*<NavLink to={"/shop"} activeClassName={"current-route"}>Shop</NavLink>*/}
                    <NavLink to={"/nft"} activeClassName={"current-route"}>NFT'S</NavLink>
                    <NavLink to={"/links"} activeClassName={"current-route"}>Affiliate Links</NavLink>
                    <NavLink to={"/news"} activeClassName={"current-route"}>News</NavLink>
                </nav>

                <div className={"socials"}>
                    <a href="https://www.instagram.com/cryptocountess"><img src={instagram} alt=""/></a>
                    <a href="https://www.tiktok.com/@cryptocountess"><img src={tiktok} alt=""/></a>
                </div>

            </div>

            <div className={"email"}><a href="mailto:hello@cryptocountess.com">hello@cryptocountess.com</a></div>



            <nav className={"footer-nav-links-2"}>
                <NavLink to={"/about"} activeClassName={"current-route"}>About</NavLink>
                <NavLink to={"/book"} activeClassName={"current-route"}>Crypto Made Clear</NavLink>
                {/*<NavLink to={"/shop"} activeClassName={"current-route"}>Shop</NavLink>*/}
                <NavLink to={"/nft"} activeClassName={"current-route"}>NFT'S</NavLink>
                <NavLink to={"/links"} activeClassName={"current-route"}>Affiliate Links</NavLink>
                <NavLink to={"/news"} activeClassName={"current-route"}>News</NavLink>
            </nav>
        </footer>
    );
}

export default Footer;