import './Home.css';
import landing1 from '../../images/landing-1-new.png';
import landing3 from '../../images/landing-2.png';
import instagram from '../../images/instagram.png';
import tiktok from '../../images/tiktok.png';
import aim1 from '../../images/aim-1.png';
import aim2 from '../../images/aim-2.png';
import aim3 from '../../images/aim-3.png';
import band from '../../images/band.png';
import book from '../../images/book-new.png';
import { Link } from "react-router-dom";

function Home() {
    return(
        <div>
            <div className={"landing-section-1"}>
                <div className={"landing-image"}>
                    <img src={landing1} alt="landing-1"/>
                </div>
                <div className={"landing-text"}>
                    <h1>The hub for all you need to start your cryptocurrency <span>journey</span></h1>
                    <p>Whether you are just starting to research cryptocurrency or have been involved in this new world for a while, our aim is to provide you with the latest information on current prices, available exchanges and to help you grow in this industry!</p>
                    <div className={"subscribe"}>
                        <input type="text" placeholder={"Your email here..."}/>
                        <button type={"submit"}>Subscribe</button>
                        <a href="https://www.instagram.com/cryptocountess/"><img src={instagram} alt=""/></a>
                    </div>
                </div>
            </div>
            <div className={"landing-section-2"}>
                <h1>Our aim is to provide you</h1>
                <div className={"aims"}>
                    <div className={"aim"}>
                        <img src={aim1} alt=""/>
                        <h2>Our Recommendations For the Best Exchanges</h2>
                        <p>Finding the best exchange for you will depend on many factors such as your location, how often you want to trade and also what device you are using. We help to break down these factors for each of the top exchanges to ensure you register with the right one for you.</p>
                    </div>
                    <div className={"aim"}>
                        <img src={aim2} alt=""/>
                        <h2>Current Prices For Top Currencies</h2>
                        <p>Compared to the stock market, the wonderful thing about cryptocurrency is that it is open and trading 24/7, 365 days a year! Because of this, prices are changing every second. We track and display these prices for your convenience.</p>
                    </div>
                    <div className={"aim"}>
                        <img src={aim3} alt=""/>
                        <h2>The Best Software and Wallets on the Market</h2>
                        <p>Knowing where to store and keep your cryptocurrency safe can be a minefield. Along with our recommendations for what software and products you can use, we also provide detailed information on what these products offer and why they might suit your needs.</p>
                    </div>
                </div>
                <Link to={"/about"}><button>Learn More</button></Link>
            </div>
            <div className={"landing-section-3"}>
                <div className={"landing-image"}><img src={landing3} alt="landing-3"/></div>
                <div className={"landing3-text"}>
                    <h1>Find us on <span>Instagram</span></h1>
                    <p>You can find us on Instagram, which is our main social media platform, where we post daily useful information, news and guidance! You can also find us on TikTok by searching cryptocountess.</p>
                    <div className={"follow"}>
                        <button type={"button"} onClick={()=> window.open('https://www.instagram.com/cryptocountess', "_blank")}>Follow!</button>
                        <a href="https://www.tiktok.com/@cryptocountess"><img src={tiktok} alt=""/></a>
                    </div>
                </div>
            </div>
            <div className={"landing-section-4"}>
                <h1>Want to hear <span>a story?</span></h1>
                <div className={"story-section"}>
                    <div className={"story-text"}>
                        <img className={"band"} src={band} alt="band"/>
                        <div className={"text"}>
                            <p>Crypto Countess was created by Holly, our founder, as part of her journey into documenting her cryptocurrency experiences. This platform, alongside the publication of Holly’s first book, Crypto Made Clear, aims to guide you on your journey in this new financial world. Holly’s dream is to build a business that helps people navigate through the cryptocurrency universe and achieve their own passive income streams and financial freedom.
                            </p>
                            <Link to={"/book"}><button>Learn more about us</button></Link>
                        </div>
                    </div>
                    <img className={"board"} src={book} alt="board"/>
                </div>
            </div>
        </div>
    );
}

export default Home;