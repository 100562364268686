import './Header.css';
import logo from "../../logo-header.png";
import { useState } from 'react';
import {Link, NavLink} from "react-router-dom";
import Ticker from "../Ticker/Ticker";

function Header() {

    const [open, setOpen] = useState(false);

    const changeOpen = () => setOpen(!open);

    return(
        <>
            <header className={"header"}>

                <div className={"header-body"}>

                    <Link to={"/"} className={"logo-title"}>

                        <img className={"header-logo"} src={logo} alt=""/>

                    </Link>

                    <svg className={"hamburger"} onClick={changeOpen} version="1.0" xmlns="http://www.w3.org/2000/svg"
                         width="35.000000pt" height="28.000000pt" viewBox="0 0 74.000000 28.000000"
                         preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,28.000000) scale(0.100000,-0.100000)"
                           fill="#000000" stroke="none">
                            <path d="M10 230 l0 -40 363 2 362 3 3 38 3 37 -365 0 -366 0 0 -40z"/>
                            <path d="M252 43 l3 -38 240 0 240 0 3 38 3 37 -246 0 -246 0 3 -37z"/>
                        </g>
                    </svg>


                    <nav className={"nav-links"}>
                        <NavLink to={"/about"} activeClassName={"current-route"}>About</NavLink>
                        <NavLink to={"/book"} activeClassName={"current-route"}>Crypto Made Clear</NavLink>
                        {/*<NavLink to={"/shop"} activeClassName={"current-route"}>Shop</NavLink>*/}
                        <NavLink to={"/nft"} activeClassName={"current-route"}>NFT'S</NavLink>
                        <NavLink to={"/links"} activeClassName={"current-route"}>Affiliate Links</NavLink>
                        <NavLink to={"/news"} activeClassName={"current-route"}>News</NavLink>
                    </nav>
                </div>

                {open && (
                    <nav className={"nav-links-mobile"}>
                        <NavLink onClick={changeOpen} to={"/about"} activeClassName={"current-route"}>About</NavLink>
                        <NavLink onClick={changeOpen} to={"/book"} activeClassName={"current-route"}>Crypto Made Clear</NavLink>
                        {/*<NavLink onClick={changeOpen} to={"/shop"} activeClassName={"current-route"}>Shop</NavLink>*/}
                        <NavLink onClick={changeOpen} to={"/nft"} activeClassName={"current-route"}>NFT'S</NavLink>
                        <NavLink onClick={changeOpen} to={"/links"} activeClassName={"current-route"}>Affiliate Links</NavLink>
                        <NavLink onClick={changeOpen} to={"/news"} activeClassName={"current-route"}>News</NavLink>
                    </nav>
                )}
            </header>

            <div className={open ? "hidden" : ""}>
                <Ticker/>
            </div>
        </>
    );
}

export default Header;