import './Shop.css';
import book1 from '../../images/book-new.png'
import book2 from '../../images/book2.jpg';
import book3 from '../../images/book3.jpg';
import book4 from '../../images/book4.png';
import amazon from '../../images/amazon.png';
import waterstsones from '../../images/waterstones.png';
import {useState} from "react";

function Shop() {
    const galleryImages = [book1, book2, book3, book4];
    const [galleryIndex, setGalleryIndex] = useState(0);
    return(
        <div>
            <div className={"shop-landing"}>
                <h1>CRYPTO MADE CLEAR</h1>
            </div>

            <div className={"book-section"}>
                <div className={"book-info"}>
                    <h1>CRYPTO MADE CLEAR</h1>
                    <p className={"book-synopsis"}>Crypto Made Clear was created to allow anyone, from any background, to be able to access and understand the cryptocurrency world.</p>
                    <hr/>
                    <div className={"book-image-link mobile"}>
                        <img src={galleryImages[galleryIndex]} alt="book"/>
                        <div className="gallery">
                            <img className={galleryIndex === 0 ? 'selected' : ''} src={book1} alt="" onClick={() => setGalleryIndex(0)}/>
                            <img className={galleryIndex === 1 ? 'selected' : ''} src={book2} alt="" onClick={() => setGalleryIndex(1)}/>
                            <img className={galleryIndex === 2 ? 'selected' : ''} src={book3} alt="" onClick={() => setGalleryIndex(2)}/>
                            <img className={galleryIndex === 3 ? 'selected' : ''} src={book4} alt="" onClick={() => setGalleryIndex(3)}/>
                        </div>
                        <div className="book-links">
                            <a className={"amazon-link"} href="https://www.amazon.com/Crypto-Made-Clear-Beginners-Cryptocurrency/dp/183849250X/"><button><span>Buy it on Amazon!</span><img src={amazon} alt="amazon logo"/></button></a>
                            <a className={"waterstones-link"} href="https://www.waterstones.com/book/crypto-made-clear/holly-ellen-stockley/9781838492502"><button><span>Buy it on Waterstones!</span><img src={waterstsones} alt="waterstones logo"/></button></a>
                        </div>
                    </div>
                    {/*<p className={"description-title"}>Description:</p>*/}
                    <p className={"description"}>Whether you want to get involved with cryptocurrency or just want to understand the subject a little better, Crypto Made Clear provides an introduction into the world of cryptocurrency in an easy-to-read style.</p>
                    <p className={"description"}>In 2019, I decided to venture into crypto despite having a dislike for numbers and finances
                        in general. Upon researching and reading more about this world, I struggled to understand what exactly crypto was as this world is full of jargon and assumed knowledge.
                        Through the use of personal experience, the ins and outs of this new financial world are
                        explored and explained without the complicated jargon commonly associated with this
                        sector.</p>
                    <p className={"description"}>Recent years have seen the adoption of the world’s first cryptocurrency, Bitcoin, into mainstream society and Crypto Made Clear aims to not only guide you through a crypto journey, but also examine the many other cryptocurrencies currently seeking the same social
                        acceptance.</p>
                    <p className={"description"}>This book includes a vast glossary for quick referencing as well as detailed explanations
                        of where and how to purchase cryptocurrency. Compared to other literature on this subject, Crypto Made Clear is written in the first person from my own experiences of entering
                        into the crypto space with no background knowledge in finance or currency trading.</p>
                    <p className={"description"}>The goal of this book is to introduce cryptocurrency to people who would normally shy
                        away or feel they were not able to comprehend this new world. Ironically, the purpose of
                        Bitcoin’s creation was to form a space within our society that is unable to be controlled
                        and exploited by the wealthy and instead allow anyone and everyone a chance to
                        enhance their financial prospects. Crypto Made Clear has been written to remove the overwhelm, the confusion and daunting aspects of cryptocurrency so that a complete novice
                        can enter into this world and have some basic knowledge by the end of the book.</p>
                </div>
                <div className={"book-image-link desktop"}>
                    <img src={galleryImages[galleryIndex]} alt="book"/>
                    <div className="gallery">
                        <img className={galleryIndex === 0 ? 'selected' : ''} src={book1} alt="" onClick={() => setGalleryIndex(0)}/>
                        <img className={galleryIndex === 1 ? 'selected' : ''} src={book2} alt="" onClick={() => setGalleryIndex(1)}/>
                        <img className={galleryIndex === 2 ? 'selected' : ''} src={book3} alt="" onClick={() => setGalleryIndex(2)}/>
                        <img className={galleryIndex === 3 ? 'selected' : ''} src={book4} alt="" onClick={() => setGalleryIndex(3)}/>
                    </div>
                    <div className="book-links">
                        <a className={"amazon-link"} href="https://www.amazon.com/Crypto-Made-Clear-Beginners-Cryptocurrency/dp/183849250X/"><button><span>Buy it on Amazon!</span><img src={amazon} alt="amazon logo"/></button></a>
                        <a className={"waterstones-link"} href="https://www.waterstones.com/book/crypto-made-clear/holly-ellen-stockley/9781838492502"><button><span>Buy it on Waterstones!</span><img src={waterstsones} alt="waterstones logo"/></button></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Shop;